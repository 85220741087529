<template>
  <div class="partyMemberForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="标题"
        :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>
      <el-form-item label="内容" prop="topicContent">
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>
      <el-form-item
        label="是否关闭点赞"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="closeComment"
      >
        <v-radio
          :radioObj="closeCommentObj"
          v-model="form.closeComment"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否关闭评价"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="closePraise"
      >
        <v-radio
          :radioObj="closePraiseObj"
          v-model="form.closePraise"
        ></v-radio>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <v-radio :radioObj="isTopObj" v-model="form.isTop"></v-radio>
      </el-form-item>
      <el-form-item label="置顶排序" prop="isTopSort" v-if="form.isTop == 1">
        <v-input placeholder="请输入" v-model="form.isTopSort"></v-input>
      </el-form-item>
      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>
      <el-form-item
        label="封面图"
        :rules="[
          {
            required: true,
            message: '请上传封面图',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="topicPicture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.topicPicture" />
      </el-form-item>
      <el-form-item label="上传类型" prop="upLoadType">
        <v-radio
          :radioObj="upLoadTypeObj"
          v-model="upLoadType"
          @change="changeUpLoadType"
        ></v-radio>
      </el-form-item>
      <el-form-item
        v-if="upLoadType == '2'"
        label="上传视频"
        :rules="[{ message: '请选择', trigger: 'blur' }]"
        prop="upLoadVideo"
      >
        <v-radio :radioObj="upLoadVideoObj" v-model="upLoadVideo"></v-radio>
      </el-form-item>
      <el-form-item
        v-if="upLoadType == '1'"
        label="图片"
        :rules="[
          {
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="picUrl"
      >
        <v-upload :limit="5" :imgUrls.sync="form.picUrl" />
      </el-form-item>
      <el-form-item
        v-if="upLoadVideo == '1'"
        label="直接上传视频"
        :rules="[
          {
            message: '请上传视频',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="videoUrl"
      >
        <v-videoUpload :limit="1" :videoUrls.sync="form.videoUrl" />
      </el-form-item>
      <el-form-item v-if="upLoadVideo == '2'" label="视频链接" prop="videoUrl">
        <v-input placeholder="请输入视频链接" v-model="form.videoUrl"></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicUrl,
  updateTopicUrl,
  getTopicListUrl,
  getTopicInfoByIdUrl,
} from "./api.js";
import {
  status,
  statusMap,
  setStatusOps,
  // isTop,
  isTopMap,
  setIsTopOps,
} from "./map.js";
export default {
  name: "onLineStudyForm",
  data() {
    return {
      statusOps: setStatusOps(),
      width: 250,
      submitConfig: {
        queryUrl: getTopicInfoByIdUrl,
        submitUrl: "",
      },
      upLoadType: "",
      upLoadTypeObj: [
        {
          name: "1",
          value: "上传图片",
        },
        {
          name: "2",
          value: "上传视频",
        },
      ],
      upLoadVideo: "",
      upLoadVideoObj: [
        {
          name: "1",
          value: "直接上传",
        },
        {
          name: "2",
          value: "视频链接",
        },
      ],
      isTopObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      closeCommentObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      closePraiseObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      form: {
        id: "",
        topicType: "18", //话题类型 固定传15
        tenantId: undefined, // 租房id
        optUser: undefined, // 用户id
        isTop: "", //是否置顶
        isTopSort: "", // 置顶排序
        topicTitle: "", // 话题标题
        topicContent: "", // 富文本内容
        topicPicture: "", // 话题封面图
        closeComment: "", //关闭点赞
        closePraise: "", //关闭评论
        status: "", // 状态
        videoUrl: "", // 视频url
        picUrl: "", // 图片
      },
      onOff: true, // 是否进入编辑
      id: "",
    };
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({
          id,
          topicType: "18",
        });
      });
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateTopicUrl : addTopicUrl;
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    changeUpLoadType() {
      this.upLoadVideo = "";
      this.form.videoUrl = "";
      this.form.picUrl = "";
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
        // this.form.closeComment = this.form.closeComment ? String(this.form.closeComment) : "";
        // this.form.closePraise = this.form.closePraise ? String(this.form.closePraise) : "";
        // this.form.isTop = this.form.isTop ? String(this.form.isTop) : "";
        if (this.form.videoUrl != "" && this.form.picUrl == "") {
          this.upLoadType = "2";
          this.upLoadVideo = "1";
        }
        if (this.form.picUrl != "" && this.form.videoUrl == "") {
          this.upLoadType = "1";
          this.upLoadVideo = "";
          // this.upLoadVideo='1'
        }
      });
    },
    submitBefore() {
      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }
      if (!this.form.tenantId) {
        this.form.tenantId = this.$store.state.app.userInfo.tenantId;
      }
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.partyMemberForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
