import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: '0',
    label: "否",
  },
  {
    value: '1',
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

export {
    status,
    statusMap,
    setStatusOps,
    isTop,
    isTopMap,
    setIsTopOps,
}