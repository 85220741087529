// 新增话题
const addTopicUrl = `/gateway/hc-neighbour/topic/addTopic`;
// 更新话题
const updateTopicUrl = `/gateway/hc-neighbour/topic/updateTopic`;
// 话题列表
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 话题详情
const getTopicInfoByIdUrl = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 删除话题
const deleteTopicURL = "/gateway/hc-neighbour/topic/deleteTopic";
export {
  deleteTopicURL,
  addTopicUrl,
  updateTopicUrl,
  getTopicListUrl,
  getTopicInfoByIdUrl,
};
